<template>
  <div class="aniBox">
    <div class="aniBox1">

      <div class="content1">
        <img class="newimg1" :class="{'div01':csShow,'div1':!csShow}" src="../../assets/image/0201.png" alt="">
        <img class="newimg1" :class="{'div02':csShow,'div2':!csShow}" src="../../assets/image/0202.png" alt="">
        <img class="newimg1" :class="{'div03':csShow,'div3':!csShow}" src="../../assets/image/0203.png" alt="">
        <img class="div4info" :class="{'div04':csShow,'div4':!csShow}" src="../../assets/image/0204.png" alt="">

        <div class="drop1">

          <transition enter-active-class="animated fadeInDownBig" leave-active-class="animated zoomOut" :duration="{enter: 600, leave: 300}">
            <img class="drop1-img" v-show="tsShow" src="../../assets/image/drop1.png" alt="">
          </transition>

          <div class="drop1-text dropTrans0" :class="{'dropTrans':tsShow}">
            <p>存量提升+增量</p>
            <span></span>
            <p>双重变现</p>
          </div>
        </div>
        <div class="drop3">
          <transition enter-active-class="animated fadeInDownBig" leave-active-class="animated zoomOut" :duration="{enter: 1200, leave: 300}">
            <img class="drop3-img" v-show="tsShow" src="../../assets/image/drop3.png" alt="">
          </transition>
          <div class="drop3-text dropTrans0" :class="{'dropTrans':tsShow}">
            <p>创新互动模式 <br/>升维用户体验</p>
            <span></span>
            <p>AI + CG <br/>视觉IP沉淀</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "pyramid",
  data() {
    return {
      csShow: false,
      tsShow: false,
    }
  },
  mounted() {
    if (this.isMobile() || document.body.clientHeight > 800) {
      this.csShow = true;
      this.tsShow = true
    } else {
      window.addEventListener("scroll", this.handleScroll);
    }
  },
  methods: {
    // 添加判断移动端方法
    isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    },
    handleScroll() {
      let scrollTop =
          window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      //设置背景颜色的透明读
      // console.log(scrollTop)
      if (scrollTop > 80) {
        this.csShow = true;
        this.tsShow = true
        window.removeEventListener("scroll", this.handleScroll);
      }
    },
  },
}
</script>

<style lang="less" scoped>
.aniBox {
  width: 100%;
  height: 4.7rem;

  .aniBox1 {
    position: relative;
    height: 100%;

  }
}

.content1 {
  width: 551px;
  height: 337px;
  margin: 100px auto;
  position: relative;

  .newimg1 {

  }

  .div1 {
    position: absolute;
    bottom: -144px;
    left: -345px;
    opacity: 0;
    transition: all 1s;
  }

  .div2 {
    position: absolute;
    top: -279px;
    left: 160px;
    opacity: 0;
    transition: all 1s;
  }

  .div3 {
    position: absolute;
    bottom: -144px;
    right: -345px;
    opacity: 0;
    transition: all 1s;
  }

  .div01 {
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 1;
    transition: all 1s;
  }

  .div02 {
    position: absolute;
    top: 0;
    left: 160px;
    opacity: 1;
    transition: all 1s;
  }

  .div03 {
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 1;
    transition: all 1s;

  }

  .div4info {
    position: absolute;
    bottom: 0;
    right: 130px;
  }

  .div4 {
    transform: scale(0.0);
    transition: all 1s;
  }

  .div04 {
    transform: scale(1.0);
    transition: all 1s;
  }

  .drop1 {
    position: absolute;
    bottom: 0;
    left: -280px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);

    .drop1-text {
      text-align: center;
      position: absolute;
      left: 102px;
      bottom: 20px;

      span {
        display: inline-block;
        width: 218px;
        height: 2px;
        border-radius: 6px;
        background: #fbe594;
        margin-bottom: 4px;
      }
    }

  }

  .drop2 {
    position: absolute;
    top: 0;
    right: -80px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);

    .drop2-text {
      text-align: center;
      position: absolute;
      right: 102px;
      bottom: 20px;

      span {
        display: inline-block;
        width: 218px;
        height: 2px;
        border-radius: 6px;
        background: #f9bca5;
        margin-bottom: 4px;
      }
    }
  }

  .drop3 {
    position: absolute;
    bottom: 0;
    right: -286px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);

    .drop3-text {
      text-align: center;
      position: absolute;
      right: 102px;
      bottom: 3px;

      span {
        display: inline-block;
        width: 218px;
        height: 2px;
        border-radius: 6px;
        background: #9cb4f7;
        margin-bottom: 4px;
      }
    }
  }
}

.dropTrans0 {
  transform-origin: center center;
  transform: scale3d(0, 1, 0);
  transition: all .3s;
}

.dropTrans {
  transform: none;
}


@media screen and (min-width: 320px) and (max-width: 992px) {
  .aniBox {
    height: 2.7rem;

    .aniBox1 {

    }
  }

  .content1 {
    width: 276px;
    height: 169px;
    margin: 100px auto;

    .newimg1 {
      width: 116px;
      height: 75px;
    }

    .div1 {
      position: absolute;
      bottom: -72px;
      left: -172px;
      opacity: 0;
      transition: all 1s;
    }

    .div2 {
      position: absolute;
      top: -140px;
      left: 80px;
      opacity: 0;
      transition: all 1s;
    }

    .div3 {
      position: absolute;
      bottom: -72px;
      right: -172px;
      opacity: 0;
      transition: all 1s;
    }

    .div01 {
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 1;
      transition: all 1s;
    }

    .div02 {
      position: absolute;
      top: 0;
      left: 80px;
      opacity: 1;
      transition: all 1s;
    }

    .div03 {
      position: absolute;
      bottom: 0;
      right: 0;
      opacity: 1;
      transition: all 1s;

    }

    .div4info {
      width: 145px;
      height: 83px;
      position: absolute;
      bottom: 0;
      right: 65px;
    }

    .div4 {
      transform: scale(0.0);
      transition: all 1s;
    }

    .div04 {
      transform: scale(1.0);
      transition: all 1s;
    }

    .drop1 {
      position: absolute;
      bottom: 55px;
      left: -50px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);

      .drop1-img {
        width: 46px;
        height: 34px;
      }

      .drop1-text {
        text-align: center;
        position: absolute;
        left: 45px;
        bottom: -4px;

        span {
          display: inline-block;
          width: 61px;
          height: 2px;
          border-radius: 6px;
          background: #fbe594;
          margin-bottom: 2px;
        }
      }

    }

    .drop2 {
      position: absolute;
      top: -17px;
      right: -19px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);

      .drop2-img {
        width: 46px;
        height: 34px;
      }

      .drop2-text {
        text-align: center;
        position: absolute;
        right: 45px;
        bottom: -3px;

        span {
          display: inline-block;
          width: 109px;
          height: 2px;
          border-radius: 6px;
          background: #f9bca5;
          margin-bottom: 2px;
        }
      }
    }

    .drop3 {
      position: absolute;
      bottom: 54px;
      right: -50px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);

      .drop3-img {
        width: 46px;
        height: 34px;
      }

      .drop3-text {
        width: 73px;
        text-align: center;
        position: absolute;
        right: 40px;
        bottom: -17px;

        span {
          display: inline-block;
          width: 61px;
          height: 2px;
          border-radius: 6px;
          background: #9cb4f7;
          margin-bottom: 2px;
        }
      }
    }
  }
}

</style>
