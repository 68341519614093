<template>
  <div class="ani2Box">
    <div class="ani2Box1">
      <u-animate-container>
        <u-animate name="zoomIn" delay="0.5s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
          <p class="title_hea">提升用户体验 产生情感共鸣</p>
        </u-animate>
      </u-animate-container>

      <div class="allimg">
        <div class="gouBox">
          <img class="gouimg1" :class="{'movegouimg1':gouShow}" src="../../assets/image/01.png" alt="">
          <img class="gouimg2" :class="{'movegouimg2':gouShow}" src="../../assets/image/02.png" alt="">
          <img class="gouimg3" :class="{'movegouimg3':gouShow}" src="../../assets/image/03.png" alt="">
          <img class="gouimg4" :class="{'movegouimg4':gouShow}" src="../../assets/image/04.png" alt="">
          <u-animate-container>
            <u-animate name="fadeIn" delay="1s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
              <img class="gouimg5" src="../../assets/image/05.png" alt="">
            </u-animate>
          </u-animate-container>

          <u-animate-container>

            <div class="aniLogo">
              <u-animate name="zoomIn" delay="1s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
                <img class="gouimg6" src="../../assets/image/06.png" alt="">
              </u-animate>
              <u-animate name="fadeInUpBig" delay="1s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
                <p class="aniLogo_text">Intelligent</p>
              </u-animate>
            </div>

          </u-animate-container>

          <u-animate-container>
            <u-animate name="fadeIn" delay="1s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
              <img class=" roundimg1" src="../../assets/image/round01.png" alt="">
            </u-animate>
          </u-animate-container>
          <u-animate-container>
            <u-animate name="fadeIn" delay="1.5s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
              <img class=" roundimg2" src="../../assets/image/round02.png" alt="">
            </u-animate>
          </u-animate-container>
          <u-animate-container>
            <u-animate name="fadeIn" delay="2s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
              <img class=" roundimg3" src="../../assets/image/round03.png" alt="">
            </u-animate>
          </u-animate-container>
          <u-animate-container>
            <u-animate name="fadeIn" delay="2.5s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
              <img class=" roundimg4" src="../../assets/image/round04.png" alt="">
            </u-animate>
          </u-animate-container>

          <div class="titleBox titleBox1">
            <p class="title">Interesting</p>
            <p class="text">以年轻人喜爱的方式<br/>进入Ta的认知</p>
          </div>
          <div class=" titleBox titleBox2">
            <p class="title">Interactive</p>
            <p class="text">科技+艺术跨界创新<br/>激发创意和交互灵感</p>
          </div>
          <div class=" titleBox titleBox3">
            <p class="title">Immersive</p>
            <p class="text">构建沉浸式的升维体验<br/>形成平台和衍生生态</p>
          </div>
          <div class=" titleBox titleBox4">
            <p class="title">Influential</p>
            <p class="text">突破想象力的边界<br/>用户参与的社交传播</p>
          </div>
        </div>

        <u-animate-container>
          <u-animate name="zoomIn" delay="0.5s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
            <div class="anifooter">
              <p class="title">AI+CG智能动画引擎</p>
              <p class="text">重新定义内容和创意营销</p>
            </div>
          </u-animate>
        </u-animate-container>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "enhance-experience",
  data() {
    return {
      gouShow: true,
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 添加判断移动端方法
    isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    },
    handleScroll() {
      let scrollTop =
          window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (this.isMobile()) {
        //移动端
        //设置背景颜色的透明读
        if (scrollTop > 450) {
          this.gouShow = false;
          window.removeEventListener("scroll", this.handleScroll);
        }
        // else{
        //   this.gouShow = true;
        // }
      } else {
        //设置背景颜色的透明读
        // console.log(scrollTop)
        if (scrollTop > 800) {
          this.gouShow = false;
          window.removeEventListener("scroll", this.handleScroll);
        }
        // else{
        //   this.gouShow = true;
        // }
      }
    },
  },
}
</script>

<style lang="less" scoped>
.ani2Box {
  width: 100%;
  height: 7.1rem;
  background: #33326F;

  .ani2Box1 {
    width: 884px;
    height: 100%;
    margin: 0 auto;

    .title_hea {
      text-align: center;
      padding: .7rem 0 1.83rem;
      font-size: 28px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      line-height: 40px;
    }

  }
}

.allimg {
  position: relative;
}

.gouBox {
  width: 267px;
  height: 267px;
  margin: 0 auto;
  position: relative;

  img {
    position: absolute;
  }

  .gouimg1 {
    top: 0;
    left: 0;
    z-index: 2;
    transition: all 1s;
  }

  .gouimg2 {
    top: 0;
    right: 0;
    z-index: 2;
    transition: all 1s;
  }

  .gouimg3 {
    bottom: 0;
    right: 0;
    z-index: 2;
    transition: all 1s;
  }

  .gouimg4 {
    bottom: 0;
    left: 0;
    z-index: 2;
    transition: all 1s;
  }

  .movegouimg1 {
    top: -300px;
    left: -300px;
    opacity: 0;
  }

  .movegouimg2 {
    top: -300px;
    right: -300px;
    opacity: 0;
  }

  .movegouimg3 {
    bottom: -300px;
    right: -300px;
    opacity: 0;
  }

  .movegouimg4 {
    bottom: -300px;
    left: -300px;
    opacity: 0;
  }


  .gouimg5 {
    top: -98px;
    z-index: 1;
  }

  .gouimg6 {
    top: 84px;
    left: 95px;
    z-index: 3;
  }

  .aniLogo {
    .aniLogo_text {
      font-size: 42px;
      color: #fff;
      position: absolute;
      top: 176px;
      left: 46px;
      z-index: 3;
    }
  }

  .roundimg1 {
    top: 0;
    left: -116px;
  }

  .roundimg2 {
    top: 0;
    right: -116px;
  }

  .roundimg3 {
    bottom: 0;
    right: -116px;
  }

  .roundimg4 {
    bottom: 0;
    left: -116px;
  }

  .titleBox {
    position: absolute;

    .title {
      font-size: 22px;
      font-weight: 500;
      color: rgba(119, 118, 255, 1);
      margin-bottom: 8px;
    }

    .text {
      font-size: 16px;
      font-weight: 400;
      color: #d1e0f7;
      line-height: 1.2;
    }
  }

  .titleBox1 {
    top: 0;
    left: -310px;
    text-align: right;
  }

  .titleBox2 {
    top: 0;
    right: -310px;
    text-align: left;
  }

  .titleBox3 {
    bottom: 0;
    right: -310px;
    text-align: left;
  }

  .titleBox4 {
    bottom: 0;
    left: -310px;
    text-align: right;
  }
}

.anifooter {
  margin-top: 35px;
  text-align: center;

  .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    color: rgba(255, 255, 255, 1);
  }

  .text {
    font-size: 14px;
    color: #d0d9e6;
  }

}

.pshow {
  display: block;
}

.mshow {
  display: none;
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .pshow {
    display: none;
  }

  .mshow {
    display: block;
  }

  .ani2Box {
    height: 760px;

    .ani2Box1 {
      width: 295px;
      height: 100%;
      margin: 0 auto;

      .title_hea {
        text-align: center;
        padding: .5rem 0 1rem;
        font-size: 20px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        line-height: 40px;
      }

    }
  }

  .gouBox {
    .roundimg1 {
      top: 273px;
      left: -40px;
      width: 45px;
    }

    .roundimg2 {
      top: 278px;
      right: 73px;
      width: 45px;
    }

    .roundimg3 {
      bottom: -154px;
      left: -40px;
      width: 45px;
    }

    .roundimg4 {
      bottom: -154px;
      left: 150px;
      width: 45px;
    }

    .titleBox {
      position: absolute;

      .title {
        font-size: 14px;
        font-weight: 500;
        color: rgba(119, 118, 255, 1);
        margin-bottom: 8px;
      }

      .text {
        font-size: 12px;
        font-weight: 400;
        color: #d1e0f7;
        line-height: 1.2;
        width: 120px;
      }
    }

    .titleBox1 {
      top: 272px;
      left: 18px;
      text-align: left;
    }

    .titleBox2 {
      top: 277px;
      right: -60px;
      text-align: left;
    }

    .titleBox3 {
      bottom: -148px;
      right: 130px;
      text-align: left;
    }

    .titleBox4 {
      bottom: -148px;
      left: 208px;
      text-align: left;
    }
  }

  .anifooter {
    margin-top: 175px;
    text-align: center;

    .title {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 10px;
      color: rgba(255, 255, 255, 1);
    }

    .text {
      font-size: 14px;
      color: #d0d9e6;
    }

  }
}
</style>
