<template>
  <div>
    <page-guide :options="options" :pc-image="backgroundImage.pcImage" :mobile-image="backgroundImage.mobileImage"></page-guide>
    <pyramid></pyramid>
    <enhance-experience></enhance-experience>
    <div style="background: #F8F8FF;">
      <realize-vlaue></realize-vlaue>
    </div>
    <contact-us></contact-us>
    <m-footer></m-footer>
  </div>
</template>

<script>
import pageGuide from '@/components/page-guide';
import pyramid from "@/view/business/pyramid";
import enhanceExperience from "./enhance-experience";
import realizeVlaue from "./realize-value"
import contactUs from "@/components/contact-us";
import footer from "@/components/footer";
import { investorIcons } from "@/assets/js/fixed_data";

export default {
  name: "index",
  components: {
    pageGuide,
    pyramid,
    enhanceExperience,
    realizeVlaue,
    contactUs,
    'm-footer': footer
  },
  data() {
    return {
      show: false,
      options: [{
        content: 'Magics',
        animate: 'fadeIn',
        delay: '0s'
      }, {
        content: '智能',
        animate: 'fadeIn',
        delay: '0.8s'
      }, {
        content: '创意',
        animate: 'fadeIn',
        delay: '0.5s'
      }, {
        content: '营销',
        animate: 'fadeIn',
        delay: '0.7s'
      }],
      backgroundImage: {
        pcImage: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/3bg0101.png',
        mobileImage: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/brand/cyyxBg.png'
      },
      investorIcons: investorIcons
    }
  }
}
</script>

<style scoped>

</style>
