<template>
  <video-carousel :videoList="videoList"></video-carousel>
</template>

<script>
import videoCarousel from "@/components/video-carousel"
import { ottVideoList } from "@/assets/js/fixed_data"

export default {
  name: "OTT",
  components: {
    videoCarousel
  },
  data() {
    return {
      videoList: ottVideoList
    }
  }
}
</script>
