<template>
  <video_swiper class="videoContainer" :videoList="mobileVideoList" :slidesPerView="3"></video_swiper>
</template>

<script>
import video_swiper from "@/components/video-swiper"
import { mobileVideoList } from "@/assets/js/fixed_data"

export default {
  name: "Mobile",
  components: {
    video_swiper
  },
  data() {
    return {
      mobileVideoList: mobileVideoList
    }
  }
}
</script>

<style scoped>

</style>
