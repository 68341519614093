<template>
  <div class="valueBox">
    <div class="omTitle">帮助媒体实现流量的双重价值</div>

    <tabs v-model="activeName" :data="tabsData" :dataHeight="dataHeight">
      <div slot="OTT" class="ott">
        <o-t-t></o-t-t>
      </div>
      <div slot="Mobile" class="mobile">
        <mobile></mobile>
      </div>
    </tabs>
  </div>
</template>

<script>
import { isMobile } from "@/assets/js/fixed_data"
import tabs from "@/components/tab";
import OTT from "./components/OTT";
import Mobile from "./components/Mobile"

export default {
  name: "realize-value",
  computed: {
    dataHeight() {
      if (this.isMobile) {
        return '5rem'
      } else {
        return '6rem'
      }
    }
  },
  components: {
    tabs, OTT, Mobile
  },
  data() {
    return {
      activeName: 'Mobile',
      tabsData: [
        {
          name: "OTT",
          label: "OTT",
        },
        {
          name: "Mobile",
          label: "Mobile",
        },
      ],
      isMobile: isMobile
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.valueBox {
  width: 1200px;
  //height: 7.1rem;
  //background: #33326F;
  margin: auto;

  .omTitle {
    padding: .8rem 0 .38rem;
    text-align: center;
    height: 40px;
    font-size: 28px;
    font-weight: 400;
    //color:rgba(255,255,255,1);
    line-height: 40px;
  }

  .ott {
    margin-top: 0.7rem
  }

  .mobile {
    margin-top: 0.5rem
  }
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .valueBox {
    width: 100%;
    //height: 6.2rem;
    min-height: 500px;
    background: #33326F;

    .omTitle {
      padding: .5rem 0 .1rem;
      font-size: 20px;
      font-weight: 500;
    }

    .ott {
      margin-top: 0
    }
  }
}

</style>
